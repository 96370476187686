
function AboutMe(props){
   const {
    personalDetails,
    } = props;

    function mapAddress(address){
      const {
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        state,
        country,
        postcode
      } = address;
      return addressLine1+ ' ' + addressLine2 + ' '+ addressLine3 + ',' + city+',' + state + ',' + postcode + ' ' + country;
    };

    return (
        <div class="about-section pt-4 px-3 px-lg-4 mt-1">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="h3 mb-3">About Me</h2>
                  <p>{props.about.shortDescription}</p>
                </div>
                {
                  personalDetails.contactDetails!=null ? 
                  <div class="col-md-5 offset-md-1">
                    <div class="row mt-2">
                      <div class="col-sm-4">
                        <div class="pb-1">Email</div>
                      </div>
                      <div class="col-sm-8">
                        <div class="pb-1 text-secondary">{personalDetails.contactDetails.email}</div>
                      </div>
                      <div class="col-sm-4">
                        <div class="pb-1">Phone</div>
                      </div>
                      <div class="col-sm-8">
                        <div class="pb-1 text-secondary">{personalDetails.contactDetails.mobile}</div>
                      </div>
                      <div class="col-sm-4">
                        <div class="pb-1">Address</div>
                      </div>
                      <div class="col-sm-8">
                        <div class="pb-1 text-secondary">{mapAddress(personalDetails.contactDetails.address)}</div>
                      </div>
                  </div>
                </div> : <></>
                }
                
              </div>
            </div>
    )
}
export default AboutMe;