import './css/main.css';
import './css/print.css';
import './css/site.css';
import './css/all.css';
import './css/loader.css';
import withData from './hoc/withData'
import { Loader } from './Components';
import  Resume  from './Resume';
import { useLocation } from 'react-router-dom';


const EnhancedComponent = withData(Resume);

function App(props) {   
  return (
   <>
    <div>
      <EnhancedComponent resumeData={props}></EnhancedComponent>
  </div>
   </>
  );
}

export default withData(App);
