
function Education(props){
  const { education } = props;
    return (
        <div class="education-section px-3 px-lg-4 pb-4">
              <h2 class="h3 mb-4">Education</h2>
              {education.map((item,index)=>
              <div class="timeline">
              <div class="timeline-card timeline-card-success card shadow-sm">
                <div class="card-body">
                  <div class="h5 mb-1">{item.courseName} <span class="text-muted h6">from {item.universityName}</span></div>
                  <div class="text-muted text-small mb-2">{item.startDate} - {item.endDate}</div>
                  {/* <div>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</div> */}
                </div>
              </div>                
            </div>)}
              
            </div>
    )}

    export default Education