function ResumeHeader(props){
  const {
    headerDetails
  } = props;

  console.log(headerDetails);

  function getInitials(name){
    var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
    return (       
  <div class="cover-bg p-3 p-lg-4 text-white">
    <div class="row">
      <div class="col-lg-4 col-md-5">
        <div class="avatar hover-effect bg-white shadow-sm p-1">
          { headerDetails.imageUrl != null ?
           <img alt="" src={headerDetails.imageUrl} width="200" height="200" /> :
           <div class="missing-avatar" width="200" height="200">{getInitials(headerDetails.name)}</div>}
        </div>
      </div>
      <div class="col-lg-8 col-md-7 text-center text-md-start">
        <h2 class="h1 mt-2" data-aos="fade-left" data-aos-delay="0">{headerDetails.name}</h2>
        <p data-aos="fade-left" data-aos-delay="100">{headerDetails.designation}</p>
        <div class="d-print-none" data-aos="fade-left" data-aos-delay="200">
          <a class="btn btn-light text-dark shadow-sm mt-1 me-1" href={headerDetails.downloadLink} target="_blank">Download CV</a>
          <a class="btn btn-success shadow-sm mt-1" href="#contact">Hire Me</a></div>
      </div>
    </div>
  </div>)
}

export default ResumeHeader;