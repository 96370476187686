import React, { useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';

const withData = (WrappedComponent)=>{ 
  return (props) => {
      const [resumeData,setResumeData] = useState(null); 
      const [isLoading, setisLoading] = useState(true);
      const { pathname } = useLocation();
      console.log(pathname.split('/')[1].toLowerCase());
      const resumeId = btoa(pathname.split('/')[1].toLowerCase());
      useEffect(() => {        
        console.log('Component has been rendered or updated');       
        fetchResume();   
      }, []); // or [dependencies] if you have any

      const fetchResume = async() => {
        console.log('Fetching Resume Data');
        const response = await fetch("http://api.mycvinfo.com/api/resume/"+resumeId)
        let data = await response.json();
        setResumeData(data)

      //   .then(response => response.json())
      // // 4. Setting *dogImage* to the image url that we received from the response above
      //   .then(data => setResumeData(data));  
        setisLoading(false);  
      }

      return <WrappedComponent {...props} resumeData={resumeData} isLoading={isLoading}/>        
      
    };
    
  }
export default withData;