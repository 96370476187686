function Skills(props){  
  const { skills } = props;
   console.log(skills);
    
    return (
      <div class="skills-section px-3 px-lg-4">
      <h3 class="h3 mb-3">Professional Skills</h3>
      <div class="row">
      {   
        skills.map((item,index)=> 
        <div class="col-md-6">                  
            <section>
              <h2>{item.Title}</h2>
              <ul class="skill-set">
                {
                  item.skillSet.map((item,index)=>    
                    <li>{item}</li>       
                  
                )}
              </ul>
            </section>
        </div>          
        )}   
         </div>   
        </div>
    )
}

export default Skills