import Social from "./Social"
function Header(props){
    return(
      <header class="d-print-none">
      <div class="container text-center text-lg-left">
        <div class="py-3 clearfix">
          <h1 class="site-title mb-0">{props.name}</h1>
          <Social social={props.social}/>
        </div>
      </div>
    </header>
    )
}

export default Header