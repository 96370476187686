import './css/main.css';
import './css/print.css';
import './css/site.css'
import './css/all.css'
import {
  ResumeHeader,
  AboutMe, 
  Skills, 
  WorkExperience,
  Education, 
  ContactUs,
  Footer,
  Header,
  Loader
} from './Components/index';
function Resume(props) {
  const {resumeData,isLoading }= props;
  return (
   <>
   
   {isLoading ? <div>
      <Loader/> 
   </div>:
   <><Header name={resumeData.personalDetails.name} social={resumeData.social} /><div class="page-content">
          <div class="container">
            <div class="cover shadow-lg bg-white">
              <ResumeHeader headerDetails={resumeData.personalDetails} />
              <AboutMe about={resumeData.about} personalDetails={resumeData.personalDetails} />
              <hr class="d-print-none" />
              <Skills skills={resumeData.skills} />
              <hr class="d-print-none" />
              <WorkExperience experienceDetails={resumeData.professionalExperience} />
              <hr class="d-print-none" />
              <div class="page-break"></div>
              <Education education={resumeData.education} />
              <hr class="d-print-none" />

            </div>
          </div>
        </div></>
   }
      </> 
  );
}

export default Resume;
