function WorkExperience(props){
  const {
      experienceDetails
     } = props;
    return(
        <div class="work-experience-section px-3 px-lg-4">
              <h2 class="h3 mb-4">Work Experience</h2>
              {experienceDetails.map((item,index)=>
                <div class="timeline">
                <div class="timeline-card timeline-card-primary card shadow-sm">
                  <div class="card-body">
                    <div class="h5 mb-1">{item.designation} <span class="text-muted h6">{item.organization}</span></div>
                    <div class="text-muted text-small mb-2">{item.start} - { item.isCurrent?"Present" : item.end }</div>
                    <div>
                      <ul>
                        {item.profileDetails.map((profileItem,index)=>
                          <li>
                            {profileItem}
                          </li>                        
                        )}
                        
                      </ul>
                    </div>
                  </div>
                </div>                
              </div>
              )}
              
            </div>
    )
}

export default WorkExperience