function Social(props){
    const {social} = props;
    return(
        <>
          <div class="site-nav">
            <nav role="navigation">
              <ul class="nav justify-content-center">
                {
                    social.map((item,index)=>
                        <li class="nav-item"><a class="nav-link" href={item.site.navigationLink} title={item.site.name}><i class={"fab fa-"+item.site.name.toLowerCase()}></i><span class="menu-title sr-only">{item.site.name}</span></a>
                     </li>
                    )
                }               
              </ul>
            </nav>
          </div>
        </>
    )
}

export default Social;